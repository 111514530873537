import Vue from "vue";
import Router from "vue-router";
import { HomeData, TokenomicsData, RoadmapData, BusinessData, AgentData, AboutUsData, LinkTreeData, AirdropsData, FaqData } from "./data";
import Components from "./components/Components.vue"
import Home from "./components/Home.vue";
import HomeV2 from "./components/home/Home.vue"
import Agent from "./components/agent/Agent.vue";
import AboutUs from "./components/aboutus/AboutUs.vue"
import Roadmap from "./components/roadmap/Roadmap.vue"
import Tokenomics from "./components/tokenomics/Tokenomics.vue"
import Blog from "./components/blog/Blog.vue"
import BlogArticle from "./components/blog/BlogArticle.vue"
import PrivacyPolicy from "./components/PrivacyPolicy.vue"
import FAQ from "./components/FAQ.vue"
import LinkTree from "./components/LinkTree.vue"
import InstallMobile from "./components/misc/Install.vue"
import AlphaReg from "./components/admin/AlphaReg.vue";

const config = require("./config");

Vue.use(Router);

const routes = [
  {
    path: "/agent",
    component: Agent,
    props: { ...AgentData },
  },

  {
    path: "/community",
    component: Agent,
    props: { ...AgentData },
  },

  {
    path: "/alpha",
    component: Home,
    component: Agent,
    props: { ...AgentData, displayAlphaRegistration: true },
  },

  {
    path: "/business",
    component: Home,
    props: { ...HomeData, businessExpanded: true },
  },

  {
    path: "/platform",
    component: Home,
    props: { ...HomeData, businessExpanded: true },
  },

  {
    path: "/aboutus",
    component: AboutUs,
    props: { ...AboutUsData },
  },

  {
    path: "/tokenomics",
    component: Tokenomics,
    props: { ...TokenomicsData },
  },

  {
    path: "/roadmap",
    component: Roadmap,
    props: { ...RoadmapData },
  },

  {
    path: "/blog",
    component: Blog,
    props: {},
  },

  {
    path: '/article',
    name: "BlogArticle",
    component: BlogArticle,
    props: {},
  },

  {
    path: "/privacy",
    component: PrivacyPolicy,
    props: {}
  },

  {
    path: "/faq",
    component: FAQ,
    props: { ...FaqData },
  },

  {
    path: "/links",
    component: LinkTree,
    props: { ...LinkTreeData },
  },

  {
    path: "/mobile",
    component: InstallMobile,
    props: {},
  },

  {
    path: "/alphareg",
    component: AlphaReg,
    props: {}
  },

  {
    path: "/:pathMatch(.*)*",
    component: HomeV2,
    props: { ...HomeData },
  },
];

if (config.hasCompontentsPage) {
  console.log("Adding /components route");
  routes.splice(0, 0, {
    path: "/components",
    component: Components,
  });
}

export default new Router({
  mode: "history",
  routes: routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
