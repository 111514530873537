<template>
  <Page :title="title">
    <v-dialog width="unset" v-model="alphaPopup">
      <alpha-registration v-bind="$props.alpha" @close="alphaPopup = false" />
    </v-dialog>
    <div class="d-flex flex-row justify-center" style="position: absolute;">
      <v-btn ref="userRegistration" elevation="3" rounded @click="openAlphaRegistrationForm()"
          class="user-registration-button mr-2 mr-sm-10 mb-2"
          >
            <div class="poppins-normal-16px">Test the app</div>
        </v-btn>
        <v-btn ref="loyaltyProgram" to="/article?catch-the-rewards-loyalty-airdrop" target="_blank" elevation="3" outlined rounded
          class="loyalty-button"
          >
            <div class="poppins-normal-16px">Loyalty Program</div>
        </v-btn>
      </div>

    <transition appear name="on-tasks">
      <v-row justify="center" align="end" class="ma-0">
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Tagging emotions in a social media post
          </div>
          <lottie :options="taskAnimationOptions1" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Working out what’s going on in a picture
          </div>
          <lottie :options="taskAnimationOptions2" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Labeling objects in images
          </div>
          <lottie :options="taskAnimationOptions3" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Explaining what’s happening in a video
          </div>
          <lottie :options="taskAnimationOptions4" class="px-sm-10 px-lg-0" />
        </v-col>
      </v-row>
    </transition>

    <transition appear name="on-header-bottom">
      <v-row no-gutters id="bottom-title" class="mt-sm-8 mt-lg-16">
        <v-col>
          <v-row justify="center">
            <v-col cols="10" lg="8">
              <div :class="titleFont" style="text-align: center">
                Make your time work for you!
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-2" justify="space-around">
            <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
              <div
                :class="normalTextFont"
                :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
              >
                Whether you’re riding the train, waiting in the doctor’s office, in the queue at the grocery store, or doing your morning routine, why not monetize your minutes?
              </div>
            </v-col>
            <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
              <div
                :class="normalTextFont"
                :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
              >
                It’s all done on Blockchain so you can start earning digital rewards without opening a bank account, passing tedious interviews or giving up insane fees to third parties.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </transition>

    <v-row class="partners my-5 my-md-16">
      <v-col class="align-start mx-5 invisible" v-animate-onscroll="{down: 'animated fadeIn'}">
        <div :class="partnerHeadlineFont" class="partners-headline">Trusted by</div>
      </v-col>
      <v-col class="invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
        <partners class="my-md-10 px-5 px-md-0" />
        <hr class="separator mx-5 mx-md-0" />
      </v-col>
    </v-row>

    <!-- Section 01 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">01</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Get the app</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10 pr-md-0" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <v-col cols="12" lg="5" class="mr-lg-5">
        <h1 :class="sectionHeadlineFont" class="mb-3 mb-md-5">Easy installation</h1>
        <div :class="normalTextFont">Timeworx.io puts a value on your time, allowing you to help companies take care of their daily repetitive tasks in exchange for a fee.<br /><br />Get rewarded just by using your everyday aptitudes and your ability to use a smartphone.</div>
        <div class="d-flex flex-md-row flex-column align-center my-5 my-md-10">
          <div class="col-8 col-md-5 align-self-md-start">
            <a href="https://play.google.com/store/apps/details?id=io.timeworx.agent" target="_blank" rel="noopener noreferrer">
              <v-img :src="require('@/assets/img/agent/googleplay.png')" width="168px"></v-img>
            </a>
          </div>
          <div class="col-8 col-md-5">
            <a href="https://testflight.apple.com/join/JTzlNn5X" target="_blank" rel="noopener noreferrer">
              <v-img :src="require('@/assets/img/agent/testflight.png')" width="168px"></v-img>
            </a>
            <div :class="subTextFont" class="mt-3">If you are asked for an invitation code, please use <span class="emphasis">JTzlNn5X</span></div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="7" class="ml-lg-n5" style="max-width: 100% !important; overflow: hidden;">
        <v-img
          :src="require('@/assets/img/agent/phone.gif')"
          max-width="750px"
          contain>
      </v-img>
      </v-col>
    </v-row>

    <!-- Section 02 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">02</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Connect your wallet</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-row no-gutters style="overflow: visible;">
            <v-col no-gutters cols="12" md="7" class="mb-10">
              <h1 :class="sectionHeadlineFont" class="mt-5 mt-md-10 mb-3 mb-md-5"><v-icon size="36px" color="#D554FF" class="mr-3">mdi-check-circle</v-icon>Take your pick</h1>
              <div :class="normalTextFont" class="mb-5 mb-md-10">Timeworx.io is a multi-chain dApp, allowing you to connect to both Injective and MultiversX, using a wide range of mobile wallet integrations.</div>
              <div class="horizontal-divider my-5 my-md-10"></div>
              <h1 :class="sectionHeadlineFont" class="mt-5 mt-md-10 mb-3 mb-md-5"><v-icon size="48px" color="#D554FF" class="mr-3">mdi-domino-mask</v-icon>Earning your trust</h1>
              <div :class="normalTextFont">Explore the app anonymously, with no upfront commitment. By choosing to connect later, you can enjoy the app, get rewards for solving tasks and have a feel for the experience, identity-free. When you’re ready, connect your wallet and claim your earnings.</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col no-gutters cols="12" md="6" :class="negativePaddingWallet">
              <div :class="walletImage"></div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Section 03 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">03</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Solve easy tasks</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <div :class="normalTextFont" class="mb-5 mb-md-10">Timeworx.io shapes a world where your time on the train could buy you a dream holiday, or waiting in line at the grocery store can make you the extra cash needed to buy that new car. Instead of mindlessly scrolling through your social media news feed, you can help growing businesses handle their repetitive tasks in exchange for a fair fee.</div>
          <h1 :class="sectionHeadlineFont" class="mt-3 mt-md-5 mb-0 mb-md-10">What kind of tasks are we talking about?</h1>
          <div class="d-flex flex-md-row flex-column justify-md-space-between my-10">
            <div class="d-flex flex-column wider align-center">
              <div :class="smallTextFont" class="px-6">Please read this text carefully. How does it make you feel?</div>
              <v-img
                  :src="require('@/assets/img/agent/phone1.png')"
                  :lazySrc="require('@/assets/img/agent/phone1@2x.png')"
                  :max-height="480"
                  :max-width="228"
                  class="mt-5 mt-md-6"
                  contain
                ></v-img>
            </div>
            <div class="vertical-divider flex-grow-1 flex-shrink-0"></div>
            <div class="horizontal-divider d-md-none my-5"></div>
            <div class="d-flex flex-column wider align-center">
              <div :class="smallTextFont" class="px-0 px-lg-6">Listen to this video carefully. Transcribe what the people are saying.</div>
              <v-img
                  :src="require('@/assets/img/agent/phone2.png')"
                  :lazySrc="require('@/assets/img/agent/phone2@2x.png')"
                  :max-height="480"
                  :max-width="228"
                  class="mt-5 mt-md-6"
                  contain
                ></v-img>
            </div>
            <div class="vertical-divider flex-grow-1 flex-shrink-0"></div>
            <div class="horizontal-divider d-md-none my-5"></div>
            <div class="d-flex flex-column wider align-center">
              <div :class="smallTextFont" class="px-6">Please select the keywords that best match the image.</div>
              <v-img
                  :src="require('@/assets/img/agent/phone3.png')"
                  :lazySrc="require('@/assets/img/agent/phone3@2x.png')"
                  :max-height="480"
                  :max-width="228"
                  class="mt-5 mt-md-6"
                  contain
                ></v-img>
            </div>
          </div>
          <div class="my-10 d-flex">
            <a href="https://www.youtube.com/watch?v=4tzdIECwhas" target="_blank" style="margin: auto;">
              <v-btn elevation="3" rounded class="mt-3 mt-md-8" :style="{
                'background-color': '#ff6200',
                'color': 'white',
                'z-index': 2,
                'text-transform': 'unset !important',
              }">
                <div class="poppins-semi-bold-white-14px">Watch our app guide</div>
              </v-btn>
            </a>
          </div>
          <div :class="normalTextFont" class="pt-md-5">With every task you solve, you receive an amount of TIX that you can later claim. The more you solve, the more you gain.</div>
        </div>
      </v-col>
    </v-row>

    <!-- Section 04 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">04</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Get better with time</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <h1 :class="sectionSubheadlineFont" class="mb-0 mb-md-10">The more you practice, the better you’ll get at solving tasks. And the better you get, the more rewards you receive!</h1>
          <div :class="normalTextFont" class="pt-5 pt-md-10">You’re able to see your stats and track your progress in your punch card:</div>
          <v-row class="ga-5 mt-5 mt-md-7 mb-5 mb-md-10 px-3 px-md-0">
            <v-col no-gutters cols="12" sm="6" lg="3">
              <div class="outlined fill-height">
                <v-responsive :aspect-ratio="1 / 1">
                  <div class="metric-box justify-center">
                    <v-img :src="require('@/assets/img/agent/icons/speed.svg')" max-width="40px" max-height="40px"></v-img>
                    <div class="poppins-semi-bold-white-22px">Speed</div>
                    <div class="poppins-normal-white-16px">How quickly you solve tasks</div>
                  </div>
                </v-responsive>
              </div>
            </v-col>
            <v-col no-gutters cols="12" sm="6" lg="3">
              <div class="outlined fill-height">
                <v-responsive :aspect-ratio="1 / 1">
                  <div class="metric-box justify-center">
                    <v-img :src="require('@/assets/img/agent/icons/proficiency.svg')" max-width="40px" max-height="40px"></v-img>
                    <div class="poppins-semi-bold-white-22px">Proficiency</div>
                    <div class="poppins-normal-white-16px">How accurately you solve tasks</div>
                  </div>
                </v-responsive>
              </div>
            </v-col>
            <v-col no-gutters cols="12" sm="6" lg="3">
              <div class="outlined fill-height">
                <v-responsive :aspect-ratio="1 / 1">
                  <div class="metric-box justify-center">
                    <v-img :src="require('@/assets/img/agent/icons/reliability.svg')" max-width="40px" max-height="40px"></v-img>
                    <div class="poppins-semi-bold-white-22px">Reliability</div>
                    <div class="poppins-normal-white-16px">How often you solve tasks</div>
                  </div>
                </v-responsive>
              </div>
            </v-col>
            <v-col no-gutters cols="12" sm="6" lg="3">
              <div class="outlined fill-height">
                <v-responsive :aspect-ratio="1 / 1">
                  <div class="metric-box justify-center">
                    <v-img :src="require('@/assets/img/agent/icons/speed.svg')" max-width="40px" max-height="40px"></v-img>
                    <div class="poppins-semi-bold-white-22px">Versatility</div>
                    <div class="poppins-normal-white-16px">How many types of tasks do you solve</div>
                  </div>
                </v-responsive>
              </div>
            </v-col>
          </v-row>
          <div :class="normalTextFont" class="py-5 py-md-10">Level up your skill set and rewards will follow. At Timeworx.io, we’re interested in getting quality out of our outcomes. This is why we offer benefits, like reward boosts, every time you level up.</div>
          <div class="d-flex justify-center my-5 my-md-10">
            <TooltipButton
              :color="'#FF5800'"
              text="Check your stats"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#FF5800'"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Section 05 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">05</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Unlock your punch card</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-row no-gutters class="align-md-center">
            <v-col no-gutters cols="12" md="7" class="my-10">
              <div :class="normalTextFont">
                Your punch card is a visual representation of your performance and acts as your on-chain proof of skills and accomplishments.
                <br /><br />
                From your punch card, you can gain insights into your current standing and get an idea of what you need to improve so you can level up.
                <br /><br />
                Your punch card is your gateway to your earnings. Once you unlock it, you are in full control of your rewards.
              </div>
              <div class="horizontal-divider my-10"></div>
              <div :class="normalTextFont" class="my-10">
                Your punch card is unlocked when you reach <span class="highlight bold">level 4</span>. 
              </div>
              <div class="d-flex justify-center justify-md-start mb-5 mb-md-10">
                <TooltipButton
                  :color="'#FF5800'"
                  text="Earn your punch card today"
                  :tooltip="'Coming soon'"
                  :tooltipColor="'#E8EAED'"
                  :tooltipTextColor="'#FF5800'"
                />
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col no-gutters cols="12" md="5" class="mr-md-n16">
              <v-img
                class="ma-auto"
                :max-width="480"
                :src="require('@/assets/img/agent/punch-card.gif')">
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Section 06 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">06</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Manage your earnings</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-row no-gutters class="align-md-center">
            <v-col no-gutters cols="12" md="7" class="my-10">
              <div :class="normalTextFont">
                Once you’ve earned enough TIX, you have an important choice to make:
              </div>
              <div :class="largeLabelFont" class="my-3 my-md-10">
                <div class="bold">do I claim my TIX?</div>
                <div>or</div>
                <div class="bold">do I stake my TIX?</div>
              </div>
              <div :class="normalTextFont" class="my-3 mb-10 my-md-10">
                Your punch card allows you to lock your unclaimed TIX for staking, and receive even more rewards. It’s all up to you. 
              </div>
              <div class="d-flex flex-column flex-md-row align-center align-md-start">
                <TooltipButton
                  class="mr-md-10 mb-4"
                  :color="'#FF5800'"
                  text="Check your balance"
                  :tooltip="'Coming soon'"
                  :tooltipColor="'#E8EAED'"
                  :tooltipTextColor="'#FF5800'"
                />
                <router-link to="/article?essential-things-to-know-about-staking-and-earnings" target="_blank">
                  <v-btn elevation="3" outlined rounded class="px-8" :style="{
                    'color': 'white',
                    'border-color': '#FF5800',
                    'z-index': 2,
                    'text-transform': 'unset !important',
                  }">
                    <div class="poppins-semi-bold-white-14px">Read more about staking</div>
                  </v-btn>
                </router-link>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col no-gutters cols="12" md="5" class="mr-md-n16">
              <v-img
                class="ma-auto"
                :max-width="480"
                :src="require('@/assets/img/agent/coin-pulse.gif')">
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Section 07 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">07</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Do your own research</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-row class="mt-5 mt-md-7 mb-5 mb-md-10 px-3 px-md-0">
            <v-col cols="12" sm="6" md="4" no-gutters>
              <div class="outlined fill-height clickable" @click="navigate('https://x.com/timeworx_io')">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/follow.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Follow us</div>
                  <div class="poppins-normal-white-16px">Follow us on X to get the latest updates</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" no-gutters>
              <div class="outlined fill-height clickable" @click="openAlphaRegistrationForm()">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/loyalty.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Early Adopter</div>
                  <div class="poppins-normal-white-16px">Participate in our loyalty program and get rewarded</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" no-gutters>
              <div class="outlined fill-height clickable" @mouseover="communityCardHover = true" @mouseleave="communityCardHover = false" @click="communityCardHover = !communityCardHover">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/community.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Community</div>
                  <div class="poppins-normal-white-16px">Join our communities on <a class="join-cta" href="https://t.me/timeworx" target="_blank">Telegram</a> and <a class="join-cta" href="https://discord.gg/4t2ZCCY5zu" target="_blank">Discord</a></div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" no-gutters>
              <div class="outlined fill-height clickable" @click="navigate('https://app.galxe.com/quest/Timeworx')">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/campaigns.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Quests</div>
                  <div class="poppins-normal-white-16px">Discover our Galxe quests and earn OATs and points</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" no-gutters @click="navigate('https://airdrops.timeworx.io')">
              <div class="outlined fill-height clickable">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/airdrops.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Airdrops</div>
                  <div class="poppins-normal-white-16px">Claim your share of the rewards from our airdrops portal</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" no-gutters>
              <div class="outlined fill-height clickable" @click="navigate('https://docs.timeworx.io')">
                <div class="dyor-box pa-6 pa-sm-8 pa-lg-12">
                  <v-img :src="require('@/assets/img/agent/icons/whitepaper.svg')" max-width="40px" max-height="40px"></v-img>
                  <div class="poppins-semi-bold-white-22px">Whitepaper</div>
                  <div class="poppins-normal-white-16px">Discover more insights from our whitepaper</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../DarkPage.vue"
import Partners from "../PartnersDark.vue";
import TooltipButton from "../TooltipButton.vue";
import AlphaRegistration from "../AlphaRegistration.vue";
import { gsap } from "gsap"
import Lottie from "vue-lottie";
import taskAnimation1 from "@/assets/people/1-video-com.json";
import taskAnimation2 from "@/assets/people/2-social-media.json";
import taskAnimation3 from "@/assets/people/3-labeling.json";
import taskAnimation4 from "@/assets/people/4-scan.json";

export default {
  name: "Agent",
  components: {
    Page, Partners, AlphaRegistration, Lottie, TooltipButton
  },
  props: [
    "title",
    "alpha",
    "displayAlphaRegistration",
  ],
  data() {
    return {
      taskAnimationOptions1: { animationData: taskAnimation1, autoplay: true },
      taskAnimationOptions2: { animationData: taskAnimation2, autoplay: true },
      taskAnimationOptions3: { animationData: taskAnimation3, autoplay: true },
      taskAnimationOptions4: { animationData: taskAnimation4, autoplay: true },
      alphaPopup: this.displayAlphaRegistration,
    }
  },
  mounted() {
    setTimeout(() => {
            window.scrollBy(0, 1)
            window.scrollBy(0, -1)
        }, 1500);

    const userRegistration = this.$refs.userRegistration.$el;
    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 4, delay: 0 });
    
    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 4,
      rotation: 180,
      ease: "bounce",
    });

    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 4,
      rotation: -180,
      ease: "bounce",
    });
    
    timeline.to(userRegistration, {
      duration: 0.1,
      scale: 1,
      rotation: 0,
      ease: "linear",
    });

    const loyaltyProgram = this.$refs.loyaltyProgram.$el;
    const timeline2 = gsap.timeline({ repeat: -1, repeatDelay: 4, delay: 2 });
    
    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 4,
      rotation: 180,
      ease: "bounce",
    });

    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 4,
      rotation: -180,
      ease: "bounce",
    });
    
    timeline2.to(loyaltyProgram, {
      duration: 0.1,
      scale: 1,
      rotation: 0,
      ease: "linear",
    });
  },
  computed: {
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return "poppins-bold-white-50px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-bold-white-40px";
            } else {
              return "poppins-bold-white-25px";
            }
          case "xs":
            return "poppins-bold-white-25px";
      }
    },
    partnerHeadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "text-center poppins-normal-white-34px";
        case "sm":
        case "xs":
          return "text-center poppins-normal-white-16px";
      }
    },
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-32px";
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    sectionHeaderFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-64px";
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    sectionHeadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "highlight poppins-bold-white-42px";
        case "sm":
        case "xs":
          return "highlight poppins-bold-white-24px";
      }
    },
    sectionSubheadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-36px";
        case "sm":
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    largeLabelFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "italic highlight poppins-normal-white-36px";
          case "sm":
          case "xs":
            return "italic highlight poppins-normal-white-28px";
      }
    },
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
            return "poppins-normal-white-20px";
          case "lg":
            return "poppins-normal-white-18px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-normal-white-18px";
            } else {
              return "poppins-normal-white-16px";
            }
          case "xs":
            return "poppins-normal-white-16px";
      }
    },
    smallTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-16px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    subTextFont() {
      return "poppins-normal-white-12px"
    },
    bottomLineHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "30px";
        default:
          return "20px";
      }
    },
    bottomTextNumColumns() {
      if (this.$isMobile()) {
        return 12;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 6;
          case "md":
            return 5;
          case "xs":
          case "sm":
            return 4;
        }
      }
    },
    pictogramNumColumns() {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 3;
          case "md":
          case "sm":
          case "xs":
            return 6;
      }
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "padding-desktop";
        case "sm":
        case "xs":
          return "padding-mobile";
      }
    },
    xPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-padding-desktop";
        case "sm":
        case "xs":
          return "x-padding-mobile";
      }
    },
    walletImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "wallet-image-desktop";
        case "sm":
        case "xs":
          return "wallet-image-mobile";
      }
    },
    negativePaddingWallet() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "negative-padding-wallet-desktop";
        case "sm":
        case "xs":
          return "negative-padding-wallet-mobile";
      }
    },
  },
  methods: {
    openAlphaRegistrationForm() {
      this.alphaPopup = true;
    },
    navigate(link) {
      window.open(link, '_blank', 'noreferrer');
    }
  }
};
</script>

<style scoped>
.user-registration-button {
  background-color: #FF5800 !important;
  color: white !important;
  text-transform: unset !important;
  padding: 20px !important;
}

.loyalty-button {
  color: white !important;
  text-transform: unset !important;
  padding: 20px !important;
}

.partners {
    max-width: 1480px;
    overflow: hidden;
}

.partners-headline {
    color: #3B3165;
    letter-spacing: 10.2px;
    text-align: left !important;
    display: block;
}

.separator {
    border: 0px;
    height: 2px;
    background: linear-gradient(90deg, #3B3165 20%, #CBBEFF 50%, #3B3165 80%);
}

.horizontal-divider {
  height: 1px !important;
  width: 100% !important;
  background-color: white !important;
}

.vertical-divider {
  width: 1px !important;
  background-color: white !important;
}

.highlight {
  color: #D554FF;
}

.emphasis {
  color: #FF5800 !important;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Courier New', Courier, monospace;
}

.padding-desktop {
  padding: 80px 160px;
}

.padding-mobile {
  padding: 20px;
}

.x-padding-desktop {
  padding: 0px 160px;
}

.x-padding-mobile {
  padding: 0px;
}

.wallet-image-desktop {
    background: url('~@/assets/img/agent/wallet@2x.png') no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.wallet-image-mobile {
    background: url('~@/assets/img/agent/wallet@2x.png') no-repeat center;
    background-size: cover;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 440px;
}

.negative-padding-wallet-desktop {
  margin-top: -80px;
  margin-bottom: -80px;
  margin-right: -160px;
}

.negative-padding-wallet-mobile {
  margin-left: -20px !important;
  margin-right: -20px !important;
  margin-bottom: -20px !important;
}

.wider {
  width: 100%;
  max-width: 100% !important;
}

.outlined {
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.20) !important;
}

.metric-box {
  display: flex;
  padding: 48px;
  align-items: center;
  gap: 20px !important;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.dyor-box {
  display: flex;
  flex-direction: column;
  gap: 48px !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.clickable {
  cursor: pointer;
}

.join-cta {
  color: #FF5800 !important;
}

.opaque {
  opacity: 0;
}

.on-tasks-enter-active {
  animation-name: slideInLeftWithFade;
  -webkit-animation-name: slideInLeftWithFade;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transform: scale(0);
}

.on-header-bottom-enter-active {
  -webkit-animation-name: slideInUpWithFade;
  animation-name: slideInUpWithFade;
  animation-duration: 1s;
  animation-delay: 1s;
  visibility: hidden;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.slideInLeftWithFade {
    -webkit-animation-name: slideInLeftWithFade;
    animation-name: slideInLeftWithFade
}


@-webkit-keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform:translateZ(0);
  }
}

@keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInUpWithFade {
  -webkit-animation-name: slideInUpWithFade;
  animation-name: slideInUpWithFade;
}

.invisible {
    opacity: 0;
}
</style>
