<template>
  <Page :title="title" :top-image="topImage">
    <v-row no-gutters v-for="(section, sectionIndex) in sections" :key="section.title" class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]">{{ (sectionIndex + 1).pad()}}</div>
        <div :class="[sectionHeaderFont, xPadding]" class="mt-2 mt-md-5">{{ section.title }}</div>
        <v-expansion-panels class="reflective-card mt-5 mt-md-10" :class="padding" flat>
          <v-expansion-panel v-for="(question, questionIndex) in section.questions" :key="question.title">
            <v-expansion-panel-header :class=questionTitleFont class="faq-section-question py-5 py-7" color="black">
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
              {{ question.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :class=questionAnswerFont class="faq-section-answer pb-5 pb-md-7" color="black">
              <div v-html=question.answer></div>
            </v-expansion-panel-content>
            <hr v-if="questionIndex != section.questions.length - 1" />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "./DarkPage.vue";
import { getFirestore, collection, getDocs, orderBy } from "firebase/firestore";

export default {
  name: "FAQ",
  metaInfo: {
    title: "FAQ"
  },
  components: { Page },
  props: [
    "title",
    "topImage",
  ],
  data() {
    return {
      sections: [],
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(getFirestore(), "faq"));
    const loadedSections = []
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      loadedSections.push({
        title: data.title,
        order: data.order,
        questions: data.questions.map((question) => {
          return {
            title: question.title,
            answer: question.answer,
          }
        }),
      });
    });
    this.sections = loadedSections.sort((a, b) => a.order - b.order);
  },
  computed: {
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "faq-section-header poppins-normal-white-32px";
        case "sm":
        case "xs":
          return "faq-section-header poppins-normal-white-16px";
      }
    },
    sectionHeaderFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "faq-section-header poppins-bold-white-64px";
        case "sm":
        case "xs":
          return "faq-section-header poppins-bold-white-32px";
      }
    },
    questionTitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-22px";
        case "sm":
        case "xs":
          return "poppins-normal-white-18px";
      }
    },
    questionAnswerFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-18px";
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "padding-desktop";
        case "sm":
        case "xs":
          return "padding-mobile";
      }
    },
    xPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-padding-desktop";
        case "sm":
        case "xs":
          return "x-padding-mobile";
      }
    },
  },
};

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}
</script>

<style scoped>

.wider {
  width: 100%;
}

.faq-section-question {
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border: 0;
}

.faq-section-answer {
  color: #C9C4D0;
  border: 0;
}

* hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #CBBEFF;
  padding: 0;
}

.padding-desktop {
  padding: 80px 160px;
}

.padding-mobile {
  padding: 20px;
}

.x-padding-desktop {
  padding: 0px 160px;
}

.x-padding-mobile {
  padding: 0px;
}

.v-expansion-panels > * {
  border-radius: 0 !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 0 !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--next-active {
  border-radius: 0 !important;
}

.v-expansion-panel--active>>>.v-expansion-panel-header {
  color: #957DFB;
}

.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel-content>>>a {
  color: #C9C4D0;
}
</style>
