<template>
  <v-slide-group ref="slide" multiple show-arrows center-active>
    <template v-slot:next>
      <v-icon color="#00FFFF" large>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:prev>
      <v-icon color="#00FFFF" large>mdi-chevron-left</v-icon>
    </template>

    <v-slide-item v-for="item in items" :key="item.label">
      <Category
        :item="item"
        v-bind:isGlowing="glowingItems[item.label]"
        @click="onItemClick(item)"
        class="ml-3 mr-md-5 my-4"
        :class="isLastItem(item) && 'mr-3'"
      />
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import Vue from "vue";
import Category from "./Category.vue";

export default {
  name: "CategoriesSlider",
  components: {
    Category,
  },
  props: ["items", "selectedItemIndex"],
  data() {
    return {
      glowingItems: this.items.map((el) => ({ [el.label]: false })),
    };
  },
  created() {
    Vue.set(this.glowingItems, this.items[0].label, true);
  },
  watch: { 
    selectedItemIndex: function(newVal, oldVal) {
      this.onItemClick(this.items[newVal], false);
    }
  },
  methods: {
    onItemClick(item, emit = true) {
      this.items.forEach((it) => {
        Vue.set(this.glowingItems, it.label, false);
      });
      Vue.set(this.glowingItems, item.label, true);
      if (emit) {
        this.$emit("selectedItem", item);
      } else if (this.$vuetify.breakpoint.lgAndDown) {
        this.$refs.slide._data.scrollOffset = this.selectedItemIndex * 130;
      }
    },
    isLastItem(item) {
      return this.items[this.items.length - 1].label == item.label;
    },
  },
};
</script>

<style>
.v-slide-group__content {
  justify-content: center;
}
</style>
