export const getInTouchData = {
    successMessage: "Thanks! We'll keep in touch.",
    errorMessage: "There was an error, please try again later.",
    marketing: 'I agree to receive news, updates, promotional info and other marketing updates from Timeworx.io'
};

export const titleLeft3Data = {
    titleLeft: "I am a living, breathing person with a sound mind.",
};

export const titleLeftData = {
    titleLeft3Props: titleLeft3Data,
};

export const textLeft3Data = {
    textLeft: "Tasks that are simple for your mind are complicated for modern computers. Did you ever think you could earn money without any prior training?",
};

export const textLeftData = {
    textLeft3Props: textLeft3Data,
};

export const componentEarthLeftData = {
    src: require("@/assets/img/home/clock_01.png"),
};

export const titleRightData = {
    titleRight: "I am a company looking to build the future of AI.",
};

export const textRightData = {
    textRight: "It’s not complicated to fix tomorrow’s problems, but it is to understand them. Did you ever think that a digital workforce able to process your data sets would be available at the click of a button?",
};

export const componentEarthDigitalRightData = {
    src: require("@/assets/img/home/clock_02.png"),
};

export const peopleTitle11Data = {
    whatAreTheTasksT: "What are the tasks that the human mind is better at than a computer?",
};

export const explainWhatsHappeningInAVideo1Data = {
    explainWhatsHappeningInAVideo: "Whether you’re riding the train, waiting in the doctor’s office, in the queue at the grocery store, or doing your morning routine, why not monetize your minutes?",
    className: "",
};

export const peopleText11Data = {
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo1Data,
};

export const explainWhatsHappeningInAVideo2Data = {
    explainWhatsHappeningInAVideo: "It’s all done on Blockchain so you can start earning digital rewards without opening a bank account, passing tedious interviews or giving up insane fees to third parties.",
    className: "get-rewarded-just-by",
};

export const peopleText12Data = {
    className: "people-text-2",
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo2Data,
};

export const peopleTitle12Data = {
    whatAreTheTasksT: "Make your time work for you!",
    className: "people-title-2",
};

export const explainWhatsHappeningInAVideo3Data = {
    explainWhatsHappeningInAVideo: "Explaining what’s happening in a video",
    className: "explain-video-1",
};

export const explainVideo1Data = {
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo3Data,
};

export const explainWhatsHappeningInAVideo4Data = {
    explainWhatsHappeningInAVideo: "Labeling objects in images",
    className: "text-labeling-objects",
};

export const explainVideo2Data = {
    className: "text",
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo4Data,
};

export const explainWhatsHappeningInAVideo5Data = {
    explainWhatsHappeningInAVideo: "Working out what’s going on in a picture",
    className: "text-whats-in-picture",
};

export const explainVideo3Data = {
    className: "text-whats-in-picture-1",
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo5Data,
};

export const explainWhatsHappeningInAVideo6Data = {
    explainWhatsHappeningInAVideo: "Tagging emotions in a social media post",
    className: "text-tag-emotion",
};

export const explainVideo4Data = {
    className: "text",
    explainWhatsHappeningInAVideoProps: explainWhatsHappeningInAVideo6Data,
};

export const peopleData = {
    peopleTitle11Props: peopleTitle11Data,
    peopleText11Props: peopleText11Data,
    peopleText12Props: peopleText12Data,
    peopleTitle12Props: peopleTitle12Data,
    explainVideo1Props: explainVideo1Data,
    explainVideo2Props: explainVideo2Data,
    explainVideo3Props: explainVideo3Data,
    explainVideo4Props: explainVideo4Data,
};

export const businessTitle11Data = {
    whatAreTheTasksT: "Process your data with speed and efficiency",
};

export const businessexplainWhatsHappeningInAVideo1Data = {
    explainWhatsHappeningInAVideo: "Aside from processing your data, our digital workforce can also provide specific media that you need to build your model.",
    className: "",
};

export const businessText11Data = {
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo1Data,
};

export const businessexplainWhatsHappeningInAVideo2Data = {
    explainWhatsHappeningInAVideo: "Our agents span the entire globe coming from various backgrounds and having different levels of education.",
    className: "agents-are-defined-by-characteristics",
};

export const businessText12Data = {
    className: "text-2-business",
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo2Data,
};

export const businessTitle12Data = {
    andManyMore: "And this is just a glimpse of what Timeworx can do!",
    whatAreTheTasksT: "Don’t have enough data for your model?\n\nTimeworx can help with that too",
    className: "title-2",
};

export const businessexplainWhatsHappeningInAVideo3Data = {
    explainWhatsHappeningInAVideo: "Media augmentation",
    className: "text-media-augmentation",
};

export const businessexplainVideo1Data = {
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo3Data,
};

export const businessexplainWhatsHappeningInAVideo4Data = {
    explainWhatsHappeningInAVideo: "Breaking down videos",
    className: "text-breaking-down-videos",
};

export const businessexplainVideo2Data = {
    className: "text-breaking-down-videos-1",
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo4Data,
};

export const businessexplainWhatsHappeningInAVideo5Data = {
    explainWhatsHappeningInAVideo: "Text analysis",
    className: "text-text-analysing",
};

export const businessexplainVideo3Data = {
    className: "text-text-analysing-1",
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo5Data,
};

export const businessexplainWhatsHappeningInAVideo6Data = {
    explainWhatsHappeningInAVideo: "Image Processing",
    className: "text-image-processing",
};

export const businessexplainVideo4Data = {
    className: "text-image-processing-1",
    explainWhatsHappeningInAVideoProps: businessexplainWhatsHappeningInAVideo6Data,
};

export const businessData = {
    peopleTitle11Props: businessTitle11Data,
    peopleText11Props: businessText11Data,
    peopleText12Props: businessText12Data,
    peopleTitle12Props: businessTitle12Data,
    explainVideo1Props: businessexplainVideo1Data,
    explainVideo2Props: businessexplainVideo2Data,
    explainVideo3Props: businessexplainVideo3Data,
    explainVideo4Props: businessexplainVideo4Data,
};

export const homeBadges = [
    { imgSrc: require('@/assets/img/t-purple-padding.svg'), label: 'tix' },
    { imgSrc: require('@/assets/img/blockchain-purple.svg'), label: 'blockchain' },
    { imgSrc: require('@/assets/img/elrond-purple.svg'), label: 'elrond' },
];

export const socialLinks = {
    email: 'mailto:contact@timeworx.io',
    twitter: 'https://twitter.com/timeworx_io',
    telegram: 'https://t.me/timeworx',
    discord: 'https://discord.gg/4t2ZCCY5zu',
    linkedin: 'https://www.linkedin.com/company/timeworx-io/',
    medium: 'https://medium.com/@radum.tix',
    reddit: 'https://www.reddit.com/user/Timeworx_io/',
    galxe: 'https://app.galxe.com/quest/Timeworx',
    instagram: 'https://www.instagram.com/timeworx.io/',
    tiktok: 'https://www.tiktok.com/@timeworx.io',
    egldcommunity: 'https://egld.community/projects/timeworx'
};

export const footerData = {
    x2022TimeworxAllRightsReserved: "@ 2022 Timeworx, All Rights Reserved",
    termsAndConditions: "Terms and conditions",
    privacyPolicy: "Privacy policy",
    faq: "FAQ",
    contactDetails: "Contact details",
    getInTouchProps: getInTouchData,
    socialProps: socialLinks,
};

export const team = [
    {
        name: 'Alexandru Gherghina',
        job: 'CEO & Co-Founder',
        img: require('@/assets/img/team/alex.png'),
        linkedin: 'https://www.linkedin.com/in/alexg1337',
    },
    {
        name: 'Radu Marin',
        job: 'COO & Co-Founder',
        img: require('@/assets/img/team/radu.png'),
        linkedin: 'https://www.linkedin.com/in/radu-corneliu-marin-62897b59',
    },
    {
        name: 'Ionut Popescu',
        job: 'Chairman of the Board & Co-Founder',
        img: require('@/assets/img/team/ionut.png'),
        wikipedia: 'https://ro.wikipedia.org/wiki/Ionu%C8%9B_Popescu',
    },
    {
        name: 'Andreea Baron',
        job: 'UI/UX Officer',
        img: require('@/assets/img/team/andreea.png'),
        linkedin: 'https://www.linkedin.com/in/andreea-baron',
    },
    {
        name: 'Silviu Stoican',
        job: 'Lead iOS Engineer',
        img: require('@/assets/img/team/silviu.png'),
        linkedin: 'https://www.linkedin.com/in/silviu-st-50941175/',
    },
    {
        name: 'Robert Timisica',
        job: 'Lead Android Engineer',
        img: require('@/assets/img/team/robert.png'),
        linkedin: 'https://www.linkedin.com/in/robert-timisica/',
    },
    {
        name: 'Ana-Maria Nistor',
        job: 'Marketing Officer',
        img: require('@/assets/img/team/ana.png'),
        linkedin: 'https://www.linkedin.com/in/anamarianistor/',
    },
    {
        name: 'Cristina Foarfa',
        job: 'Wordsmith',
        img: require('@/assets/img/team/cristina.png'),
        linkedin: 'https://www.linkedin.com/in/cristina-foarfa-6411086/',
    },
];

export const advisors = [
    {
        name: 'Andrei Stan, Drd',
        job: 'MBA, FCCA',
        title: 'Product',
        img: require('@/assets/img/team/andi.png'),
        linkedin: 'https://www.linkedin.com/in/andi-stan/',
    },
    {
        name: 'Arturas Feiferas',
        job: 'Banking Professional',
        title: 'Finance & Compliance',
        img: require('@/assets/img/team/arturas.png'),
        linkedin: 'https://www.linkedin.com/in/arturas-feiferas-8415582b/',
    },
    {
        name: 'Prof. Dr. Eng. Ciprian Dobre',
        job: 'Vice-Rector at UNSTPB',
        title: 'Research & Academics',
        img: require('@/assets/img/team/ciprian.png'),
        linkedin: 'https://www.linkedin.com/in/cipriandobre/',
    },
];

export const quote = {
    text: '“Humanity has always remained constant—with the same bodies, brains, minds—through the Roman Empire, Biblical times, and the Stone Age. If we told our ancestors in the Stone Age about our lives today, they would think we are already Gods. But the truth is that even though we have developed more sophisticated tools, we are the same animals. We have the same emotions, the same minds. The coming revolution will change that. It will change not just our tools, it will change the human being itself.”',
    author: 'Yuval Harari'
};

export const AlphaRegistrationData = {
    titleLine1: 'Get registered',
    titleLine2: 'for early app testing!',
    body: 'Fill in this form and become one of the first people to get access to the Timeworx.io app. After submitting the form, we will send you an email with detailed instructions for installing the app.\n\nEarly app testing is carried out on the Injective Testnet and on the MultiversX Devnet.\n\nChoose your preferred mobile OS:',
    button: 'Register',
    successMessage: "We've sent you instructions to your email address.",
    errorMessage: "There was an error, please try again later.",
    img: require('@/assets/img/agent/alpha-registration-form-graphic.png'),
    lazyImg: require('@/assets/img/agent/alpha-registration-form-graphic.png'),
    marketing: "I agree to receive news, updates, promotional info and other marketing updates from Timeworx.io",
};

export const AgentData = {
    title: 'Which tasks is the human mind better at than computers?',
    alpha: AlphaRegistrationData
};

export const BusinessCards = [
    {
        icon: require('@/assets/img/business/flexibility-icon.svg'),
        title: 'Flexible',
        text: 'Employing qualified staff to carry out repetitive tasks can be extremely costly and complex. Timeworx enables you to access our network of distributed agents on demand for a fraction of the price.',        
    },
    {
        icon: require('@/assets/img/business/accurate-icon.svg'),
        title: 'Accurate',
        text: 'We have designed a set of metrics to identify areas for improvement, which we constantly strive to exceed. The platform automatically generates more agents as needed to maintain this accuracy level at no additional cost to you.',        
    },
    {
        icon: require('@/assets/img/business/optimization-icon.svg'),
        title: 'Optimized',
        text: 'We build solutions that streamline your work and reduce the need for human involvement while increasing the work volume handled. The more you work with us, the more efficient our systems become as we are able to gradually replace human agents with AI ones.',        
    },
];

export const businessSection1 = {
    title: 'Why should I use Timeworx?',
    content: 'Timeworx is a powerful, scalable, and futureproof platform for businesses to process data, either specific datasets or real-time data, easily and accurately by using a decentralized workforce incentivized via Blockchain technologies. We unleash the power of human intelligence to provide performance validation and tuning for your Machine Learning and AI systems to ensure accuracy and relevance. We support a wide array of AI and Machine Learning models, from data collection, NLP & speech, and content relevance to linguistics and computer vision.',
    cards: BusinessCards,
    button: [
        'Go to console',
        'Browse our dataset catalog',
    ],
};

export const businessSection2 = {
    title: 'Why should I trust Timeworx?',
    content: 'You have a guarantee that your information is handled securely, as Timeworx uses the latest in Blockchain technology to encrypt and store it all. And because we use the Blockchain, you can be sure that you’re getting a tamper-proof result that you can trust.',
    button: 'Learn more',
};

export const businessSection3 = {
    title: 'Why should I trust the data processed by Timeworx?',
    content: 'Timeworx empowers people to use their spare time as a way to generate the income they need to spoil their loved ones, supplement their salary, or save towards their goals by solving tasks in their capacity as agents. As such, Timeworx creates a scalable and futureproof platform where people stake their current and upcoming rewards in order to guarantee the accuracy of their work. Agents are incentivized to maintain the same efficiency and rate of work that leads to timely delivery of your processed data, and our state-of-the-art algorithms ensure redundancy and cross-checking of results obtained by agents. Furthermore, Blockchain technologies enable our trustless environment where workers present verifiable track records of efficiency and speed.',
    button: 'Learn more',
};

export const businessSection4 = {
    title: 'What can I do in Timeworx?',
    content: [
        'Timeworx has a simple and intuitive, yet powerful UI in which you can seamlessly define your data processing pipelines.',
        'You need to start from a data source, either by ingesting your own datasets, or by requesting agents to provide data to you. Timeworx supports multiple data formats such as tabular, text, images, audio, video, etc. From there on you start connecting processing blocks either automated, or involving human agents until you reach your desired outcome. Moreover, you are in full control of configuring every such block, but you can always start from our recommendations until you get the hang of it.',
    ],
    img: require('@/assets/img/business/exemplu@2x.png'),
    lazyImg: require('@/assets/img/business/exemplu@2x.png'),
    imgSrc: '@/assets/img/business/exemplu@2x.png',
    usecase: [
        { 
            title: 'Missing values',
            img: require('@/assets/img/business/missing-values@2x.png'),
            lazyImg: require('@/assets/img/business/missing-values.png'),
        },
        { 
            title: 'Monster Mashup',
            img: require('@/assets/img/business/monster-mashup@2x.png'),
            lazyImg: require('@/assets/img/business/monster-mashup.png'),
        },
    ],
    button: 'Get started',
};

export const businessSection5 = {
    title: 'How much will this cost me?',
    content: [
        'The vision behind Timeworx is one of welfare and fairness for all. As such, our goal is to bridge the gap between businesses that need access to a cost-effective solution for processing their data, and people who are able to process that data for them in exchange for equitable rewards. This implies that the cost of your data processing is directly related to the amount and to the complexity of the tasks that you need agents to solve.',
        'In Timeworx, this relationship is embodied in the TIX utility token.',
        'Whenever you create a pipeline, our Oracle determines a Task reward in TIX to be awarded to agents for solving your tasks, which is fixed throughout the lifetime of processing your data. This makes your data processing costs predictable, as well as transparent both to you and the agents.',
        'Why Blockchain? Because it allows Timeworx to lower the operating costs for agent rewards. Agents also get additional sources of revenue, such as Staking. In the end, everybody wins.',
    ],
    button: 'Get a price estimate',
};

export const businessSection6 = {
    title: 'How can I scale my business in the future?',
    content: [
        'Timeworx is a Machine Learning company at its core and we are fully aware of the different issues originating from the lack of properly annotated data. We are committed to providing a world class service while also learning ourselves, further developing our models which in turn will lower the service fees of the platform.',
        'The more you work with us, the more cost efficient your pipeline becomes as we’re gradually able to replace human agents with AI ones.',
    ],
    img: require('@/assets/img/business/business-scale@2x.png'),
    lazyImg: require('@/assets/img/business/business-scale.png'),
};

export const businessFlowData = {
    s1: businessSection1,
    s2: businessSection2,
    s3: businessSection3,
    s4: businessSection4,
    s5: businessSection5,
    s6: businessSection6,
};

export const HomeData = {
    topPageProps: {
        titleLeftProps: titleLeftData,
        textLeftProps: textLeftData,
        componentEarthLeftProps: componentEarthLeftData,
        titleRightProps: titleRightData,
        textRightProps: textRightData,
        componentEarthDigitalRightProps: componentEarthDigitalRightData,
        peopleProps: peopleData,
        businessProps: businessData,
        homeBadgesProps: {
            badges: homeBadges
        },
    },
    agentProps: AgentData,
    businessProps: businessFlowData,
};

export const tokenomicsCategories = [
    { 
        order: '0',
        label: 'Community',
        value: '165,000,000 TIX',
        percentage: 33,
        description: 'The largest allocation is dedicated to our community and ecosystem, through staking rewards, aidrops for our loyal users and support for community projects.',
        color: '#7B63DF',
        subcategories: [
            {
                label: 'Staking Rewards',
                value: '75,000,000 TIX',
                percentage: 15,
                description: 'Rewards offered to users that stake TIX for securing the platform'
            },
            {
                label: 'Airdrops',
                value: '75,000,000 TIX',
                percentage: 15,
                description: 'Tokens distributed to the community for showing appreciation and for offering incentives'
            },
            {
                label: 'Community Projects',
                value: '15,000,000 TIX',
                percentage: 3,
                description: 'Tokens to be distributed to innovative community projects that we will be supporting'
            },
        ],
    },
    {
        order: '1',
        label: 'Growth',
        value: '140,000,000 TIX',
        percentage: 28,
        description: 'The Growth allocation is reserved for ensuring the liquidity of our ecosystem throughout time and financing our future marketing and sales pipelines. Unused funds are burned once the next amount is released.',
        color: '#D554FF',
        subcategories: [
            {
                label: 'Liquidity Reserve',
                value: '50,000,000 TIX',
                percentage: 10,
                description: 'Reserve for listing on exchanges'
            },
            {
                label: 'Marketing',
                value: '40,000,000 TIX',
                percentage: 8,
                description: 'Exclusive for marketing efforts which will grow exponetially throughout time'
            },
            {
                label: 'Company Reserve',
                value: '50,000,000 TIX',
                percentage: 10,
                description: '“Rainy-day” fund to only be used in case of extreme emergencies'
            },
        ],
    },
    {
        order: '2',
        label: 'Public Sales',
        value: '29,604,000 TIX',
        percentage: 5.92,
        description: "As true believers of DeFi, we have reserved an allocation to allow our community to invest in our Public Sale.",
        color: '#8B00E7',
    },
    {
        order: '3',
        label: 'Private Sales',
        value: '55,396,000 TIX',
        percentage: 11.08,
        description: 'Tokens allocated for our seed and private sale investors to reward our oldest and most loyal Partners.',
        color: '#B09EFF',
        subcategories: [
            {
                label: 'Seed',
                value: '52,000,000 TIX',
                percentage: 10.4,
                description: 'Tokens allocated for seed funding round'
            },
            {
                label: 'Private Sale',
                value: '3,396,000 TIX',
                percentage: 0.68,
                description: 'Tokens allocated for the Private Sale founding round'
            },
        ],
    },
    {
        order: '4',
        label: 'Bootstrapping',
        value: '50,000,000 TIX',
        percentage: 10,
        description: "Pledge for supporting academic research and for building our initial use cases for acquiring new business. All of these tokens will eventually find their way into the hands of the community as rewards for solving tasks.",
        color: '#A53CFF',
    },
    {
        order: '5',
        label: 'Team',
        value: '45,000,000 TIX',
        percentage: 9,
        description: 'Incentive for upholding the highest standards in the continuous development of our platform, as well as for growing the team, for years to come.',
        color: '#957DFB',
    },
    {
        order: '6',
        label: 'Advisors',
        value: '15,000,000 TIX',
        percentage: 3,
        description: "Rewards for the invaluable advice, guidance and insights that we have received and continue to expect from our advisors.",
        color: '#CB93FF',
    },
];

export const TokenomicsData = {
    title: 'Tokenomics',
    topImage: require('@/assets/img/top/tokenomics.png'),
    topContent: 'At Timeworx.io, we leverage the power of decentralized environments where humans can utilize their time to solve simple and repetitive tasks that machines cannot solve. Humans can monetize this time by earning TIX - our product utility currency. Ultimately, the task solutions provide labeled data for machine learning. The TIX utility token is used to power the data processing platform, can only be used for requesting services from Timeworx.io, and can only be obtained by solving tasks. Aside from enabling transactions, the TIX token plays a vital role in our ecosystem by offering other features like staking for securing trust in the platform and receiving rewards in exchange. This comprehensive strategy for the token function maintains its value and enhances user involvement with the platform.',
    tokenomicsCategoriesProps: tokenomicsCategories,
};

export const FaqData = {
    title: 'FAQ',
    topImage: require('@/assets/img/top/faq.png'),
}

export const airdropsCategories = [
    { 
        order: '0',
        label: 'Loyalty Programme',
        value: '7,000,000 TIX',
        percentage: 14,
        description: 'We have earmarked a gigantic reserve of TIX to be airdropped among our community based on the tasks each member has completed in our <a href="https://timeworx.io" target="_blank">Close Alpha</a> mobile app.',
        color: '#7B63DF',
        link: 'https://timeworx.io/article?6-1-million-tix-party',
    },
    {
        order: '1',
        label: 'Injective Ecosystem Festival',
        value: '1,000,000 TIX',
        percentage: 2,
        description: 'Timeworx.io has been featured in Injective Ecosystem Festival organized by OKX, and we want to celebrate this with you, our awesome community! We’ve prepared a <a href="https://app.galxe.com/quest/OKXWEB3/GCGmttTpas" target="_blank">Galxe campaign</a> just for you. Solve it and become eligible for this airdrop.',
        color: '#D554FF',
        link: 'https://app.galxe.com/quest/OKXWEB3/GCGmttTpas',
    },
    {
        order: '2',
        label: 'PartnerStaking Delegators',
        value: '16,000,000 TIX',
        percentage: 32,
        description: 'Stake with Partner Staking on <a href="https://partnerstaking.com/stake-inj" target="_blank">Injective</a> & <a href="https://partnerstaking.com/stake-egld" target="_blank">MultiversX</a>, and get massively rewarded in $TIX! There will be 4 airdrops, one every 3 months, starting 3 months after Timeworx.io’s IDO, based on weekly random snapshots.',
        color: '#8B00E7',
        link: 'https://twitter.com/PartnerStaking/status/1773454852284199006',
    },
    // {
    //     order: '3',
    //     label: 'ICO Buyers',
    //     value: '16,000,000 TIX',
    //     percentage: 32,
    //     description: '',
    //     color: '#B09EFF',
    // },
    // {
    //     order: '4',
    //     label: 'Galxe',
    //     value: '6,000,000',
    //     percentage: 12,
    //     description: "",
    //     color: '#A53CFF',
    // },
    // {
    //     order: '5',
    //     label: 'Injective Grant DAO',
    //     value: '1,000,000 TIX',
    //     percentage: 2,
    //     description: '',
    //     color: '#957DFB',
    // },
    {
        order: '6',
        label: 'Not yet announced',
        value: '26,000,000 TIX',
        percentage: 52,
        description: "We've got a lot more surprises waiting for you! Keep your eyes peeled and make sure to check in from time to time, to be the first to get the news.",
        color: '#CB93FF',
    },
];


export const AirdropsData = {
    titleShadow: 'Aidrops',
    title: 'Airdrops',
    topContent: 'At the core of our project, we very much appreciate and value the time, effort, feedback, and dedication of you, our great community. It really feels great to have so many fantastic friends around us. As an expression of our love and gratitude, we\'ve have dedicated 10\% of our total TIX supply to our community and ecosystem.',
    totalSupply: '50,000,000 TIX tokens',
    subtitle: 'Aidrop Supply ',
    airdropsCategoriesProps: airdropsCategories,
};


export const roadmapItems = [
    {
        label: 'Out in the wild', 
        content: "This is it! We've made our debut and we're ready to talk about what we've got planned.",
        passed: true,
        icon: require('@/assets/img/roadmap/rocket-cyan-left.svg'),
    },
    { 
        label: 'Q4 2022 - Proof of Concept', 
        content: "We’re doubling down and releasing the initial version of our platform. We’re preparing a few task templates that will allow us to build a rewarding model for our Agents.",
        passed: true,
        icon: require('@/assets/img/roadmap/proof-concept-cyan.svg'),
    },
    { 
        label: 'Q1 2023 - Mobile App Alpha', 
        content: "Those few task templates are now many. We are now validating our internal architecture and proving our state of the art algorithms that ensure accuracy and speed in generating data.",
        passed: true,
        icon: require('@/assets/img/roadmap/alpha-cyan-left.svg'),
    },
    { 
        label: 'Q1 2023 - New partnership', 
        content: "We're proud to partner up with the most iconic academic representative in the Romanian IT&C landscape: the Faculty of Automatic Control and Computer Science from UPB.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    { 
        label: 'Q2 2023 - New tasks, new challenges', 
        content: "We're rolling out new tasks in the app to help our AI learn the ropes and easily do those human things that are so simple for us but are oh-so-tricky for the machines.",
        passed: true,
        icon: require('@/assets/img/roadmap/tasks-cyan-left.svg'),
    },
    { 
        label: 'Q2 2023 - New partnership', 
        content: "Together with EnterTeq, we are building and training machine learning models to help the retail industry read, identify, extract & analyze information from receipts.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    {
        label: 'Q3 2023 - Loyalty Program', 
        content: "We are grateful for everyone’s focus on testing the limits of the platform. That’s why we’re announcing a few ways in which we will be rewarding your current efforts and incentivising our future growth.",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan-left.svg'),
    },
    { 
        label: 'Q3 2023 - New partnership', 
        content: "Partner Staking have not only been our incubator, supporting growth, acting as pre-seed fund and promoter for us, but also our infrastructure backbone, providing the servers and hosting the services we need.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    { 
        label: 'Q4 2023 - Injective', 
        content: "We are live on the Injective Testnet! With lightning fast transaction times, extremely low fees, and a thriving community, Injective is an ecosystem where our product can properly develop its true potential.",
        passed: true,
        icon: require('@/assets/img/roadmap/injective-cyan-left.svg'),
    },
    { 
        label: 'Q1 2024 - Whitepaper',
        content: "We know everyone’s curious to get more details from behind the scenes, and here they are.",
        passed: true,
        icon: require('@/assets/img/roadmap/white-paper-cyan.svg'),
    },
    {
        label: 'Q2 2024 - Loyalty Program 2.0', 
        content: "Simply put, you’ve wowed us. We've raised the stakes and increased our airdrop allocation for you, our amazing community. Join in, solve tasks, and let’s keep this party rocking!",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan-left.svg'),
    },
    {
        label: 'Q3 2024 - Even more tasks!', 
        content: "From Sarcasm Detection to Human Pose Estimation, we're revolutionizing the data processing world with a wide variety of tasks which will shape the future of AI. Have you explored them in the app yet? If not, it's time to start your journey!",
        passed: true,
        icon: require('@/assets/img/roadmap/tasks-cyan.svg'),
    },
    {
        label: 'Q4 2024 - New partnership', 
        content: "We're very excited to bring AI and drive utility into the ecosystem alongside DojoSwap, one of the OG builders from Injective",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan-left.svg'),
    },
    {
        label: 'Q4 2024 - Airdrop portal launch', 
        content: "Say hello to our freshly baked portal, where the community can check airdrop eligibility and earnings in real time ",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan.svg'),
    },
    {
        label: 'Q1 2025 - Surprize', 
        content: "What could this be? Let's wait and see!",
        passed: false,
        animated: true,
        icon: require('@/assets/img/roadmap/celebrate-left.svg'),
    },
    { 
        label: 'Q1 2025 - Public sale', 
        content: "Launchpad commitments have been confirmed! Everyone will get their chance to invest in our platform!",
        passed: false,
        animated: true,
        icon: require('@/assets/img/roadmap/public-sale-orange.svg'),
    },
    {
        label: 'Q1 2025 - Mobile App Beta', 
        content: "We’re almost there! Maybe the most critical step before the big launch is proving the entire platform works using some select businesses’ datasets in dire need of processing.",
        passed: false,
        icon: require('@/assets/img/roadmap/beta-orange-left.svg'),
    },
    { 
        label: 'Q1 2025 - Release', 
        content: "It’s been a blast! We’re now ready to build the future of AI with our partners. We’re confident Timeworx.io can now scale to meet any demand and you can finally monetize all of that invested time.",
        passed: false,
        icon: require('@/assets/img/roadmap/release-orange.svg'),
    },
    { 
        label: 'Q2 2025 - Console App Beta', 
        content: "We’re finally unveiling the powerful web app that enables businesses to specify their data processing needs, bringing in new tasks and new opportunities for the entire community.",
        passed: false,
        icon: require('@/assets/img/roadmap/console-orange-left.svg'),
    },
    { 
        label: 'Into the Great Unknown', 
        content: "We’re far from done! In fact, we’re just beginning to add some exciting new features to our platform that will make it even better. We cannot add everything in one page, so make sure to stick with us while we reveal more.",
        passed: false,
        icon: require('@/assets/img/roadmap/unknown-orange.svg'),
    },
]

export const RoadmapData = {
    title: 'Roadmap',
    topImage: require('@/assets/img/top/roadmap.png'),
    roadmapItems: roadmapItems,
};

export const BusinessData = {
    titleShadow: 'Business',
    title: 'Business',
    topContent: 'Businesses who are looking to process data, either specific datasets or real time data, can use our platform to get their tasks done quickly and accurately. They can also be sure that their information is handled securely, as we use the latest in Blockchain technology to encrypt and store it all. And because we use the Blockchain, businesses can be sure that they’re getting a tamper-proof result that they can trust.\n\nWe are creating a scalable and futureproof platform where agents stake their current and upcoming rewards in order to guarantee the accuracy of their work. Agents are incentivized to maintain the same efficiency and rate of work that leads to timely delivery of your processed data.',
    middleTitle: 'Advantages:',
    cards: BusinessCards
};

export const StakingData = {
    title: 'Partner Staking',
    img: require('@/assets/img/aboutus/ps-logo-white.png'),
    imgVertical: require('@/assets/img/aboutus/ps-logo-white-vertical.png'),
    texts: [
        'Partner Staking was founded by a unique mix of Software Engineers, Architects, and well-established Entrepreneurs with vast expertise in financial investments. We started this project as a way to manage our own cryptocurrency investments, but we are committed to contributing to MultiversX as a whole, by providing users with a fresh, new experience of interacting with the blockchain.',
        'Partner Staking has come forward as a staking provider with backing from early investors in MultiversX, who want to enable eGold holders to share their long-term vision in a secure manner.',
        'In addition to our web tools, we are developing a dedicated staking mobile app, to fully support our customers manage their stakes on the go, but also provide other features, such as a mobile oriented Explorer, transaction tracking and exporting.',
        'We have strongly believed in MultiversX since its inception and their rapid progress is further strengthening our beliefs. We want to enable other long-term MultiversX supporters to contribute to the network’s security and earn fair rewards for doing so.',
        'Marius Dogaru, Partner Staking Co-Founder',
    ],
};

export const AboutUsData = {
    titleShadow: 'About us',
    title: 'About us',
    topContent: [
        'Timeworx™ is an ongoing investigation into the nature of time, machine learning, and their relationship with human beings.',
        'Our story starts with the Injective™ and MultiversX™ partnerships that allowed us to delve deeper into these networks and discover the best ways our experts could contribute. Blockchain technology has been changing the way we do business for a while. As a team of geeks and avid fans of these developments, we were exploring a few novel Machine Learning concepts within the crypto space.',
        'On one cold winter evening, we reached a point where we had very little data to work with. Training a Machine Learning model requires vast amounts of processed data – which we utterly lacked at the time.',
        'To cut a long, but definitely not boring, story short, we came up with a vision of bridging the gap between the crypto community, regular individuals, and future-driven companies by developing a Machine Learning model working alongside real humans for exceptional accuracy.',
    ],
    middleContent: [
        'This sparked the idea of using Machine Learning models to generate data for other Machine Learning models - and then feed the results back into their training sets. And we reward the people that help us in our very own TIX token, which can be converted into real money anytime.',
        'This is the story of a group of product builders who decided to repurpose their skills and bring forth a platform that benefits individuals and companies equally. Companies get their datasets analyzed and elevated by a mix of human and machine intelligence. Humans leverage their know-how and experience in a million ways that have one thing in common: accurate results.',
        'Together, we will make time work for everyone.',
    ],
    picContent: [ "Time", "Rewards", "Goals", "Data" ],

    ourMission: "Our Mission",
    timeworksIsOnAMi: "Timeworx is on a mission to help make your time work for you. Whether you’re riding the train, waiting in the doctor’s office, in the queue at the grocery store, or doing your morning routine, we provide people across the globe with an opportunity to monetize their minutes.\nBy connecting individuals - who have a few spare minutes on their hands to perform simple tasks on mobile, with tech-driven businesses - who need help processing their data, we strive to create a digital ecosystem where everyone wins!",
    ourVision: "Our Vision",
    weEnvisionTimework: "We envision Timeworx as a leading platform for individuals who are looking for a quick, simple, and reliable way to make additional income. We see our platform empowering people to use their spare time as a way to generate the income they need to spoil their loved ones, supplement their salary, or save towards their goals.\nWith cutting-edge technology as the basis of our business, we look forward to establishing our name as a trusted way for businesses to process data with speed and efficiency.",
    missionVisionBgImg: require('@/assets/img/aboutus/mission-vision@2x.png'),
    missionVisionBgImgLazy: require('@/assets/img/aboutus/mission-vision.png'),

    whoIsTheWeBehi: "Who is the “we” behind Timeworx? At the core, we are a group of tech professionals with proven experience in our fields, focused solely on building innovative products. After years of working in the corporate world, we decided to put bureaucracy and red tape behind us, and to use our skills and talents towards creating something that would make a real difference in people’s lives. We’re all passionate about making time work for everyone and we look forward to growing a community of both business and individuals who will pioneer the way forward alongside us.",
    whoWeAre: "Who we are",
    aboutUs: "About Us",
    teamTitle: "Team",
    quote: quote,
    team: team,
    advisorsTitle: "Advisors",
    advisors: advisors,
    staking: StakingData,
};

export const GetInTouchData = {
    title: [
        'Keep Me Updated',
        'Schedule a meeting with our consultants',
        'Get in touch',
    ],
};

export const LinkTreeData = {
    homepage: "https://timeworx.io",
    userjourney: "http://timeworx.io/agent",
    twitter: socialLinks.twitter,
    telegram: socialLinks.telegram,
    discord: socialLinks.discord,
    linkedin: socialLinks.linkedin,
    instagram: socialLinks.instagram,
    tiktok: socialLinks.tiktok,
    galxe: socialLinks.galxe,
    egldcommunity: socialLinks.egldcommunity
}
