<template>
  <div class="footer-container">
    <div class="footer"  v-animate-onscroll.repeat="{down: 'animated slideInUpWithFade'}">
      <get-in-touch-dark v-bind="$props.getInTouchProps" />
      <v-container fluid no-gutters>
        <v-row justify="space-between" class="my-10">
          <v-col cols="12" md="3" class="text-center text-md-start">
            <img class="mt-2 pa-0"
              :src="require('@/assets/img/logo/logo-white-secondary-nopad.svg')"
              contain
            />
          </v-col>
          <v-col cols="12" md="9" lg="7">
            <social-media-icons-dark v-bind="$props.socialProps" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <hr class="footer-separator" />
          </v-col>
        </v-row>
        <v-row class="my-10">
          <v-col cols="5" md="4" class="pr-6 pr-md-0 py-1">
            <p :class="textFont">
              {{ x2022TimeworxAllRightsReserved }}
            </p>
          </v-col>
          <v-col>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="6" md="7">
            <v-row justify="end">
              <v-col cols="12" md="4" class="text-end py-1"><div class="px-2" :class="textFont">{{ termsAndConditions }}</div></v-col>
              <v-col cols="12" md="3" class="text-end py-1"><router-link to="/privacy"><div class="px-2" :class="textFont">{{ privacyPolicy }}</div></router-link></v-col>
              <v-col cols="12" md="1" class="text-end py-1"><router-link to="/faq"><div class="px-2" :class="textFont">{{ faq }}</div></router-link></v-col>
              <v-col cols="12" md="2" class="text-end py-1"><a href="https://docs.timeworx.io/other-information/media-kit" target="_blank"><div class="px-2 highlight" :class="textFont">Media Kit</div></a></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import GetInTouchDark from "./GetInTouchDark.vue";
import SocialMediaIconsDark from "./SocialMediaIconsDark.vue";

export default {
  name: "FooterDark",
  components: {
    SocialMediaIconsDark,
    GetInTouchDark,
  },
  props: [
    "x2022TimeworxAllRightsReserved",
    "termsAndConditions",
    "privacyPolicy",
    "faq",
    "contactDetails",
    "getInTouchProps",
    "socialProps",
  ],
  computed: {
    textFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        default:
          return "poppins-normal-white-12px";
      }
    },
  },
};
</script>

<style scoped>
.footer-container {
  background-color: #000000;
  margin: auto;
  width: 100%;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1240px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.footer-1 {
  max-width: 1920px;
  width: 100vw;
}

.footer-separator {
  opacity: 0.1;
}

.highlight {
  color: #FF5800 !important;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

@-webkit-keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform:translateZ(0);
  }
}

@keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInUpWithFade {
  -webkit-animation-name: slideInUpWithFade;
  animation-name: slideInUpWithFade;
}
</style>
