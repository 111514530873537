<template>
    <div :class="background">
        <v-container class="page-container">
            <transition appear name="on-header">
                <v-row :class="top" class="align-center">
                    <v-col id="header-heading">
                        <v-row>
                            <v-col>
                                <transition appear name="on-heading" v-on:after-enter="onHeadingEntered()">
                                    <h1 :class="heading" :style="headingStyle">The <span class="heading-span">data processing</span><br />layer for
                                        next gen AI</h1>
                                </transition>
                            </v-col>
                        </v-row>
                        <v-row>
                            <transition appear name="on-subheading" v-on:after-enter="onSubheadingEntered()">
                                <v-col :class="subheading" :style="subheadingStyle" class="mt-md-2 pa-0">
                                    <span>built on </span><a href="https://injective.com/" target="_blank"><img class="mx-1 mx-md-2 logo-inj"
                                        :src="require('@/assets/img/home/logo_injective.svg')" :height="injLogoHeight" /></a><span>and</span><a
                                        href="https://multiversx.com/" target="_blank"><img class="mx-1 mx-md-2 logo-mvx"
                                        :src="require('@/assets/img/home/logo_multiversx.svg')" :height="mvxLogoHeight" /></a><br v-if="$vuetify.breakpoint.smAndDown" /><span>(and more to come)
                                    </span>
                                </v-col>
                            </transition>
                        </v-row>
                    </v-col>
                </v-row>
            </transition>
            <v-row class="mt-0 mb-md-16">
                <transition appear name="on-community-card">
                    <v-col md="6">
                        <router-link to="/community">
                            <div class="pa-5 pa-md-10 mr-md-8 community-card card reflective-card">
                                <div :class="cardHeadline">Get rewarded</div>
                                <div :class="cardBody" class="mt-5 mt-md-10">Did you ever think you could shape the future of AI while earning
                                    money without any prior training?</div>
                                <div class="text-center mt-5 mt-md-10">
                                    <v-btn rounded class="cta pa-md-7">
                                        <span :class="cardButton">
                                            Join our community
                                        </span>
                                        <v-icon right :size="$vuetify.breakpoint.mdAndUp ? '20px' : '16px'" dark class="ml-1 ml-md-4">mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </router-link>
                    </v-col>
                </transition>
                <transition appear name="on-platform-card">
                    <v-col md="6">
                        <router-link to="/platform">
                            <div class="pa-5 pa-md-10 ml-md-8 business-card card reflective-card">
                                <div :class="cardHeadline">Label your data</div>
                                <div :class="cardBody" class="mt-5 mt-md-10">Did you ever imagine tapping into a digital workforce to label your
                                    data at the click of a button?</div>
                                <div class="text-center mt-5 mt-md-10">
                                    <v-btn rounded class="cta pa-md-7" append-icon="arrow-right">
                                        <span :class="cardButton">
                                            Discover our platform
                                        </span>
                                        <v-icon right :size="$vuetify.breakpoint.mdAndUp ? '20px' : '16px'" dark class="ml-1 ml-md-4">mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </router-link>
                    </v-col>
                </transition>
            </v-row>
            <v-row class="partners my-5 my-md-16">
                <v-col class="align-start mx-5 invisible" v-animate-onscroll="{down: 'animated fadeIn'}">
                    <div :class="mission" class="partners-headline">Trusted by</div>
                </v-col>
                <v-col class="invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
                    <partners class="my-md-10 px-5 px-md-0" />
                    <hr class="separator mx-5 mx-md-0" />
                </v-col>
            </v-row>
        </v-container>
        <div>
            <v-container class="page-container">
                <v-row class="mb-5 my-md-10 px-5 invisible" v-animate-onscroll="{down: 'animated fadeIn'}">
                    <v-col class="justify-center">
                        <div :class="mission">Together we can bridge the gap between human and artificial intelligence and
                            solve the ground truth for AI</div>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 reflective-card invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
                    <v-col>
                        <v-row>
                            <v-col>
                                <h2 :class="sectionH2">Digital transformation for your business</h2>
                            </v-col>
                        </v-row>
                        <v-row class="mb-md-10">
                            <v-col>
                                <h3 :class="sectionH3">Transition from human data processing to automated data processing</h3>
                            </v-col>
                        </v-row>
                        <v-row class="my-10" no-gutters>
                            <v-col class="align-self-center" cols="12" lg="4">
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Composability</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Effortlessly define your data processing pipelines according to
                                            your
                                            needs</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">AI-Assistance</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Take advantage of the builtin capabilities for AI-assisted data
                                            processing</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="4">
                                <v-img contain :src="require('@/assets/img/home/data_processing.png')" width="428px" />
                            </v-col>
                            <v-col class="align-self-center mt-5 mt-lg-0" cols="12" lg="4">
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Crowdsourcing</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Instantly get access to a scalable workforce of people across the
                                            world</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="my-md-3 my-lg-10" cols="12">
                                        <h4 :class="sectionH4">Next gen AI</h4>
                                        <div :class="sectionDiv" class="mt-1 mt-lg-6">Easily leverage AI models in your data processing pipelines at
                                            affordable prices</div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <v-col class="text-center">
                                <router-link to="/platform">
                                    <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Get started today</v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 reflective-card invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
                    <v-col>
                        <v-row><v-col>
                                <h2 :class="sectionH2">Spotlight on quality</h2>
                            </v-col></v-row>
                        <v-row class="mb-lg-10"><v-col>
                                <h3 :class="sectionH3">Ensuring the consistent quality of processed data is paramount to our platform</h3>
                            </v-col></v-row>
                        <v-row>
                            <v-col>
                                <h4  :class="sectionH4">Metrics</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">The performance of each agent is constantly assessed for
                                    ensuring the quality of data processing, and transparently stored as on-chain proofs.
                                </div>
                            </v-col>
                            <v-col class="flex-grow-0 px-16" v-if="$vuetify.breakpoint.lgAndUp">
                                <div style="width: 1px; background: white; height: 100%"></div>
                            </v-col>
                            <v-col cols="12" v-else>
                                <hr />
                            </v-col>
                            <v-col>
                                <h4 :class="sectionH4">Gamification</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">The platform implements incentives for ensuring that our agents
                                    are responsible, engaged and are constantly seeking to improve their performance.</div>
                            </v-col>
                            <v-col class="flex-grow-0 px-16" v-if="$vuetify.breakpoint.lgAndUp">
                                <div style="width: 1px; background: white; height: 100%"></div>
                            </v-col>
                            <v-col cols="12" v-else>
                                <hr />
                            </v-col>
                            <v-col>
                                <h4 :class="sectionH4">Trustlessness</h4>
                                <div :class="sectionDiv" class="justified mt-1 mt-lg-6">No agent is considered trusted, whether human or AI. The ground
                                    truth in data processing is determined through replication and consensus.</div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <v-col class="text-center">
                                <a href="https://docs.timeworx.io" target="_blank">
                                    <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Learn more from our whitepaper</v-btn>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row :class="section" class="my-5 my-md-10 w-100 reflective-card invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
                    <v-row class="w-100"><v-col>
                            <h2 :class="sectionH2">Build next gen AI</h2>
                        </v-col></v-row>
                    <v-row class="mb-lg-10"><v-col>
                            <h3 :class="sectionH3">Make the most of the virtuous cycle between data processing and AI</h3>
                        </v-col></v-row>
                    <v-row class="w-100 my-lg-10">
                        <v-col>
                            <hr />
                        </v-col>
                    </v-row>
                    <v-row :style="{'gap': $vuetify.breakpoint.lgAndUp ? '20px' : '40px;'}" class="pa-3 pa-lg-0">
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Generate</h4>
                            <div :class="sectionDiv"class="mt-6">Obtain human generated content based on your requirements</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Annotate</h4>
                            <div :class="sectionDiv" class="mt-6">Get your data labeled by a diversified global workforce</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Evaluate</h4>
                            <div :class="sectionDiv" class="mt-6">Apply human feedback in your active learning processes</div>
                        </v-col>
                        <v-col class="my-lg-10" cols="12" md>
                            <h4 :class="sectionH4">Monetize</h4>
                            <div :class="sectionDiv" class="mt-6">Deploy your ML models directly in the platform and earn rewards</div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-md-10">
                        <v-col class="text-center">
                            <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
                                <v-btn :class="sectionButton" rounded size="x-large" class="px-6">Set up a meeting with our experts</v-btn>
                            </a>
                        </v-col>
                    </v-row>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import Page from "../Page.vue";
import TextQuote from "../TextQuote.vue";
import Partners from "../PartnersDark.vue";

export default {
    name: "HomeV2",
    metaInfo: {
        title: "Home"
    },
    components: { Page, TextQuote, Partners },
    props: [],
    data() {
        return {
            headingStyle: {
                'transform': 'scale(0)'
            },
            subheadingStyle: {
                'opacity': 0,
            }
        }
    },
    mounted() {
        setTimeout(() => {
            window.scrollBy(0, 1)
            window.scrollBy(0, -1)
        }, 2000);
    },
    computed: {
        heading() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "heading heading-desktop poppins-bold-white-80px";
                case "sm":
                case "xs":
                    return "heading heading-mobile poppins-bold-white-24px";
            }
        },
        subheading() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "subheading subheading-desktop poppins-normal-white-24px";
                case "sm":
                case "xs":
                    return "subheading subheading-mobile poppins-normal-white-12px";
            }
        },
        mission() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-normal-white-34px";
                case "sm":
                case "xs":
                    return "text-center poppins-normal-white-16px";
            }
        },
        cardHeadline() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-bold-white-40px";
                case "sm":
                case "xs":
                    return "text-center poppins-bold-white-24px";
            }
        },
        cardBody() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "text-center poppins-normal-white-20px";
                case "sm":
                case "xs":
                    return "text-center poppins-normal-white-12px";
            }
        },
        cardButton() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "button poppins-semi-bold-white-20px";
                case "sm":
                case "xs":
                    return "button poppins-semi-bold-14px";
            }
        },
        background() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "background-desktop";
                case "sm":
                case "xs":
                    return "background-mobile";
            }
        },
        top() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "top-desktop";
                case "sm":
                case "xs":
                    return "top-mobile";
            }
        },
        injLogoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "";
                case "sm":
                case "xs":
                    return "18px";
            }
        },
        mvxLogoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "";
                case "sm":
                case "xs":
                    return "12px";
            }
        },
        section() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "section section-desktop";
                case "sm":
                case "xs":
                    return "section section-mobile";
            }
        },
        sectionH2() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-bold-white-42px";
                case "sm":
                case "xs":
                    return "poppins-bold-white-24px";
            }
        },
        sectionH3() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-normal-white-20px";
                case "sm":
                case "xs":
                    return "poppins-normal-white-12px";
            }
        },
        sectionH4() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-bold-white-20px";
                case "sm":
                case "xs":
                    return "poppins-bold-white-16px";
            }
        },
        sectionDiv() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "poppins-normal-white-16px";
                case "sm":
                case "xs":
                    return "poppins-normal-white-12px";
            }
        },
        sectionButton() {
            switch (this.$vuetify.breakpoint.name) {
                case "xl":
                case "lg":
                case "md":
                    return "button poppins-semi-bold-white-14px";
                case "sm":
                case "xs":
                    return "button poppins-semi-bold-white-14px";
            }
        },
    },
    methods: {
        onHeadingEntered() {
            this.headingStyle = {
                'transform': 'scale(1)'
            }
        },
        onSubheadingEntered() {
            this.subheadingStyle = {
                'opacity': 1,
            }
        }
    }
};
</script>

<style scoped>
.background-desktop {
    background: linear-gradient(180deg, #310293 0%, #100032 10%, #000000 20%);
}

.background-mobile {
    background: linear-gradient(180deg, #310293 -0.5%, #100032 2.5%, #000000 7%);
}

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1480px;
    margin: auto;
}

.top-desktop {
    background: url('~@/assets/img/home/top.png') no-repeat center;
    background-size: cover;
    width: 2560px;
    padding-top: 140px;
    padding-bottom: 100px;
}

.top-mobile {
    background: url('~@/assets/img/home/top.png') no-repeat center;
    background-size: contain;
    background-position: center;
    width: 776px;
    padding: 80px 0px;
    margin-bottom: -40px;
}

.partners {
    max-width: 1480px;
    overflow: hidden;
}

.heading {
    text-align: center;
}

.heading-desktop {
    line-height: 96px;
}

.heading-mobile {
    line-height: 36px;
}

.heading-span {
    background: linear-gradient(270deg, #D655FF 0%, #FF5800 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subheading {
    text-align: center;
}

.subheading-desktop {
    line-height: 36px;
}

.subheading-mobile {
    line-height: 18px;
}

.subheading-desktop>div {
    display: inline-block;
}

.subheading-mobile>div {
    display: inline-block;
}

.subheading>span {
    display: inline-block;
}

.subheading-desktop > a > .logo-inj {
    margin-bottom: -6px;
}

.subheading-desktop > a > .logo-mvx {
    margin-bottom: 0px;
}

.subheading-mobile > a > .logo-inj {
    margin-bottom: -4px;
    height: 16px;
}

.subheading-mobile > a > .logo-mvx {
    margin-bottom: 0px;
    height: 11px;
}

.mission {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: white;
}

.partners-headline {
    color: #3B3165;
    letter-spacing: 10.2px;
    text-align: left !important;
    display: block;
}

.community-card {
    margin-top: 3px;
    margin-bottom: 3px;
    cursor: pointer;
}

.community-card:hover {
    margin-top: 0px;
    margin-bottom: 0px;
    border: 4px solid #FF5800 !important;
    border-radius: 20px;
    box-shadow: 0px 0px 80px 0px #FF580044;
    background-clip: unset;
    background-origin: unset;
    overflow: hidden; 
}

.community-card>div>button {
    background-color: #FF5800 !important;
    color: white !important;
}

.business-card {
    margin-top: 3px;
    margin-bottom: 3px;
    cursor: pointer;
}

.business-card:hover {
    margin-top: 0px;
    margin-bottom: 0px;
    border: 4px solid #9417F2 !important;
    border-radius: 20px;
    box-shadow: 0px 0px 80px 0px #9417F244;
    background-clip: unset;
    background-origin: unset;
    overflow: hidden;
}

.business-card>div>button {
    background-color: #9417F2 !important;
    color: white !important;
}

.button {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
}

.cta {
    text-transform: unset !important;
}

.separator {
    border: 0px;
    height: 2px;
    background: linear-gradient(90deg, #3B3165 20%, #CBBEFF 50%, #3B3165 80%);
}

.section-desktop {
    padding: 80px 160px;
}

.section-mobile {
    padding: 40px 20px;
}

.section>>>h2 {
    text-align: left;
    color: white;
}

.section>>>h3 {
    text-align: left;
}

.section>>>h4 {
    text-align: left;
    color: #FF5800;
    text-transform: uppercase;
}

.section>>>div {
    text-align: left;
    color: white;
}

.section>>>button {
    background-color: #FF5800 !important;
    text-align: center;
    color: white;
    text-transform: unset !important;
}

.w-100 {
    width: 100%;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.justified {
    text-align: justify !important;
    text-justify: inter-word !important;
}

.on-header-enter-active {
    animation: fade-in-header 0.9s;
}

@keyframes fade-in-header {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(50%) scale(1.5);
    opacity: 0.15;
  }
  50% {
    transform: translateY(10%) scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.on-heading-enter-active {
    animation: fade-in-heading 0.6s;
    animation-delay: 0.4s;
}

@keyframes fade-in-heading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.on-subheading-enter-active {
    animation: fade-in-subheading 0.6s;
    animation-timing-function: ease-out;
    animation-delay: 0.7s;
}

@keyframes fade-in-subheading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.on-community-card-enter-active {
    animation-name: pop-up-card;
    -webkit-animation-name: pop-up-card;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transform: scale(0);
}

.on-platform-card-enter-active {
    animation-name: pop-up-card;
    -webkit-animation-name: pop-up-card;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
    opacity: 0;
    transform: scale(0);
}

@keyframes pop-up-card {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes pop-up-card {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.slideInLeftWithFade {
    -webkit-animation-name: slideInLeftWithFade;
    animation-name: slideInLeftWithFade
}

.invisible {
    opacity: 0;
}
</style>